<template>
    <b-container fluid>
        <div class="card card-primary">
            <div class="card-header">
                <h3 class="card-title">Filialbestellung: <template v-if="notEmptyObject(order)">{{ order.ordernumber }}</template></h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6" style="display: flex; flex-direction: column;">
                        <div class="card card-primary card-outline" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                            <div class="card-header">
                                <h3 class="card-title">Filiale</h3>
                            </div>
                            <div class="card-body" v-if="notEmptyObject(order)">
                                {{ order.store.name }}<br>
                                {{ order.store.street }} {{ order.store.house_number }}<br>
                                {{ order.store.zipcode }} {{ order.store.city }}<br>
                            </div>
                        </div>
                        <!-- <div class="card card-primary card-outline" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                            <div class="card-header">
                                <h3 class="card-title">Sonstiges</h3>
                            </div>
                            <div class="card-body" v-if="notEmptyObject(order)">
                                <textarea class="form-control form-control-sm" v-model="order.info" rows="3"></textarea>
                            </div>
                        </div> -->
                    </div>
                    <div class="col-md-6">
                        <div class="card card-primary card-outline" style="height: 45vh">
                            <div class="card-header">
                                <h2 class="card-title">Bestellbare Artikel</h2>
                                <div class="card-tools">
                                    <div class="input-group input-group-sm">
                                        <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="getAllArticles" v-model="search" />
                                        <button type="submit" class="btn btn-default btn-sm" @click.prevent="getAllArticles">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body overflow-auto">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <a href="#" @click.prevent="changeSort('ordernumber')">Artikelnummer</a>
                                                <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                            </th>
                                            <th>Artikelname</th>
                                            <th>Preis</th>
                                            <th>Flags</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(article,index) in articles.data" :key="article.id" :class="article.article.active == 0 ? 'table-danger' : ''" @dblclick="addArticle(index)">
                                            <td>{{ article.ordernumber }}</td>
                                            <td>
                                                {{ generateName(index) }}
                                            </td>
                                            <td>{{ article.price | toCurrency}}</td>
                                            <td>
                                                <span class="ml-2 badge bg-primary" v-if="article.laststock == 1">AdS</span>
                                                <span class="ml-2 badge bg-danger" v-if="article.not_available == 1">z.Z.n.l.</span>
                                                <span class="ml-2 badge bg-success" v-if="article.mhd != null">MHD</span>
                                                <span class="ml-2 badge bg-warning" v-if="article.article.seasonal == 1">Saison </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="clearfix align-middle card-footer">
                                <div class="row">
                                    <div class="col-md-12">
                                        <pagination class="float-left" :data="articles" @pagination-change-page="getAllArticles" :limit="3"></pagination>
                                        <select class="float-right form-control-sm" v-model="params.per_page">
                                            <option value="25">25 Artikel</option>
                                            <option value="50">50 Artikel</option>
                                            <option value="75">75 Artikel</option>
                                            <option value="100">100 Artikel</option>
                                            <option value="125">125 Artikel</option>
                                            <option value="150">150 Artikel</option>
                                        </select>
                                        <p v-if="notEmptyObject(articles)" class="float-right mr-2">Anzeige Eintrag {{ articles.meta.from }} - {{ articles.meta.to }} von {{ articles.meta.total }}</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-success card-outline" style="height: 45vh">
                            <div class="card-header">
                                <h2 class="card-title">Bestellte Artikel</h2>
                                <div class="card-tools">
                                    <button class="btn btn-primary btn-sm" @click="addFreeArticle"><i class="fas fa-plus"></i> Freie Position</button>
                                </div>
                            </div>
                            <div class="card-body overflow-auto">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>HAN</th>
                                            <th>Artikelnummer</th>
                                            <th>Artikelname</th>
                                            <th>Anzahl</th>
                                            <th>Kundenbestellungen</th>
                                            <th>Filialregal Nullbestand</th>
                                            <th>Aktion</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(article,index) in order.items" :key="article.id">
                                            <td>
                                                <input v-if="article.type == 'free'" type="text" v-model="order.items[index].han" class="form-control form-control-sm" />
                                                <span v-else>{{ article.han }}</span>
                                            </td>
                                            <td>{{ article.ordernumber }}</td>
                                            <td>
                                                <input v-if="article.type == 'free'" type="text" v-model="order.items[index].name" class="form-control form-control-sm" />
                                                <span v-else>{{ article.name }}</span>
                                            </td>
                                            <td><input type="number" class="form-control form-control-sm" v-model="order.items[index].qty" /></td>
                                            <td><input type="number" class="form-control form-control-sm" v-model="order.items[index].customer_qty" /></td>
                                            <td class="text-center">
                                                <input type="checkbox" v-model="order.items[index].null_stock" true-value="1" false-value="0">
                                            </td>
                                            <!-- <td>
                                                <input v-if="article.type == 'free'" type="number" v-model="order.items[index].purchase_price" class="form-control form-control-sm" />
                                                <span v-else>{{ article.purchase_price | toCurrency }}</span>
                                            </td> -->
                                            <td><button type="button" class="btn btn-danger btn-sm" @click="deleteArticle(index)"><i class="fas fa-trash"></i></button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-md-12 text-right" v-if="notEmptyObject(order)">
                        <button type="button" class="btn btn-primary mr-1" @click="editOrder()" :disabled="order.items.length <= 0" >Speichern</button>
                        <button type="button" class="btn btn-danger mr-1" @click="release()" :disabled="order.items.length <= 0" v-if="order.status.name == 'open'">Freigeben</button>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    name: "StoreOrdersDetails",
    title: "Filialbestellung anpassen",

    data(){
        return {
            order: {},
            articles: {},
            params: {
                sort_field: 'ordernumber',
                sort_direction: 'desc',
                per_page: 25,
                distributor_id: '',
            },
            search: '',
        }
    },

    watch: {
        params: {
            handler () {
                this.getAllArticles();
            },
            deep: true
        },
    },

    methods:{
        deleteArticle(index){
            this.$delete(this.order.items, index);
        },
        addFreeArticle(){
            this.order.items.unshift({
                han: 'FP',
                ordernumber: 'FP',
                qty: '1',
                name: 'Freie Position',
                purchase_price: 0,
                type: 'free',
                article_detail_id: 0,
                null_stock: "0"
            })
        },
        editOrder(){
            this.axios
            .put("/store-orders/" + this.$route.params.id, 
                this.order,
            )
            .then(() => {
                this.$swal({
                    icon: "success",
                    title: "Bestellung erstellt",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    });
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.$Progress.fail();
            });
        },
        release()
        {
            this.axios
                .post("/store-orders/" + this.$route.params.id + "/set/ordered", {
                    name: 'release'
                })
                .then(() => {
                    this.$router.push({name: 'store-orders.index'});
                    this.$swal({
                        icon: "success",
                        title: "Bestellung freigegeben.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    })
                })
        },
        addArticle(index){
            var article = this.order.items.find(item => item.ordernumber == this.articles.data[index].ordernumber);
            if(article == undefined)
            {
                this.order.items.unshift({
                    ordernumber: this.articles.data[index].ordernumber,
                    name: this.generateName(index),
                    producer: this.articles.data[index].article.producer.name,
                    price: this.articles.data[index].price,
                    qty: 1,
                    purchase_price: this.articles.data[index].purchase_price,
                    article_detail_id: this.articles.data[index].id,
                    type: 'article',
                    null_stock: "0"
                });    
            }
            else
            {
                let temp;
                this.order.items.forEach((element, index) => {
                    if(element.ordernumber == article.ordernumber)
                    {
                        temp = element;
                        this.order.items.splice(index, 1);
                    }
                })
                temp.qty += 1;
                this.order.items.unshift(temp);
            }
        },

        loadStoreOrder(){
            this.$Progress.start();
            this.axios
                .get("/store-orders/" + this.$route.params.id)
                .then((response) => {
                    this.order = response.data.data;
                    this.params.distributor_id = this.order.distributor_id;
                    this.getAllArticles();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getAllArticles(page = 1) {
            this.$Progress.start();
            this.axios
                .get("/details", {
                    params: {
                        zZnl: 1,
                        active: 1,
                        page,
                        search: this.search.length >= 4 ? this.search : '',
                        ...this.params
                    }
                })
                .then((response) => {
                    this.articles = response.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        generateName(index)
        {
            var article = this.articles.data[index];
            var name = article.article.name;

            article.values.forEach(function (value) {
                name = name + ' - ' + value.name;
            });

            return name;
            
        },
    },

    created(){
        this.loadStoreOrder();
    }
}
</script>

<style>

</style>